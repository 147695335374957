import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'

export default defineAppConfig({
  cmsService: {
    tenant: 'PL',
    storeId: 1,
  },
  faq: {
    sendQuestionsToCallCenter: false
  }
})
